export class PracticeDto {
  discipline?: string;

  focus?: string;

  // Versorgungsbereich
  supplyArea?: string;

  // Zulassungsbeschränkungen
  admissionRestriction?: string;

  // Versorgunsauftrag
  supplyMission?: string;

  hiredDoctors?: number;

  hiredStaff?: number;

  revenue?: number;

  profit?: number;

  form?: string;

  name?: string;
}
