export class AddressDto {
  street?: string;

  houseNumber?: string;

  zip?: string;

  city?: string;

  lon?: number;

  lat?: number;
}
