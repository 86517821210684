export const supply_area = [
  { de: 'Hausärztlich', value: 'gp' },
  { de: 'Fachärztlich', value: 'specialist' },
  { de: 'Nicht Zutreffend', value: 'not_applicable' },
];

export const supply_mission = [
  { de: 'Volle Zulassung', value: 'full_approval' },
  { de: 'Halbe Zulassung', value: 'semi_approval' },
  { de: 'Jobsharing', value: 'job_sharing' },
];

export const admission_restriction = [
  { de: 'Ja', value: 'true' },
  { de: 'Nein', value: 'false' },
  { de: 'Nicht Zutreffend', value: 'not_applicable' },
];
