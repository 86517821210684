import { PracticeDto } from './practice.dto';
import { PropertyDto } from './property.dto';

export class OfferDto {
  id?: string;

  state?: string = 'draft';

  userId?: string;

  name?: string;

  price?: number;

  description?: string;

  prospectInfo?: string;

  wishedSellDate?: Date;

  practice: PracticeDto = new PracticeDto();

  property: PropertyDto = new PropertyDto();

  createdAt?: Date;

  updatedAt?: Date;

  images: string[] = [];

  chatId?: string;
}
