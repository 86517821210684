export const disciplines = [
  { de: 'Zahnarzt', value: 'dentist' },
  { de: 'Allgemeinmediziner', value: 'general' },
  { de: 'Anatomie', value: 'anatomy' },
  { de: 'Arbeitsmedizin (Betriebsmedizin)', value: 'occupational' },
  { de: 'Augenheilkunde', value: 'ophthalmology' },
  { de: 'Biochemie', value: 'biochemistry' },
  { de: 'Chirurgie', value: 'surgery' },
  {
    de: 'Frauenheilkunde und Geburtshilfe (Gynäkologie)',
    value: 'obstetrics_gynaecology',
  },
  { de: 'Hals-Nasen-Ohren-Heilkunde', value: 'otorhinolaryngology' },
  { de: 'Haut- und Geschlechtskrankheiten', value: 'skin_sex' },
  { de: 'Humangenetik', value: 'human_genetics' },
  { de: 'Hygiene und Umweltmedizin', value: 'hygiene_environment' },
  { de: 'Innere Medizin', value: 'internal' },
  { de: 'Kinder- und Jugendmedizin', value: 'kids' },
  {
    de: 'Kinder- und Jugendpsychiatrie und -psychotherapie',
    value: 'psycho_kids',
  },
  { de: 'Laboratoriumsmedizin', value: 'laboratory' },
  {
    de: 'Mikrobiologie, Virologie und Infektionsepidemiologie',
    value: 'microbio_viro_infection',
  },
  { de: 'Mund-Kiefer-Gesichtschirurgie', value: 'oral_maxillofacial_surgery' },
  { de: 'Neurochirurgie', value: 'neurosurgery' },
  { de: 'Neurologie', value: 'neurology' },
  { de: 'Nuklearmedizin', value: 'nuclear' },
  { de: 'Öffentliches Gesundheitswesen', value: 'public' },
  { de: 'Pathologie', value: 'pathology' },
  { de: 'Pharmakologie', value: 'pharmacology' },
  {
    de: 'Phoniatrie und Pädaudiologie',
    value: 'phoniatrics_paediatric_audiology',
  },
  {
    de: 'Physikalische und Rehabilitative Medizin',
    value: 'physical_rehabilitation',
  },
  { de: 'Physiologie', value: 'physiology' },
  { de: 'Psychiatrie und Psychotherapie', value: 'psychiatry_psychotherapy' },
  {
    de: 'Psychosomatische Medizin und Psychotherapie',
    value: 'psychosomatic_psychotherapy',
  },
  { de: 'Radiologie', value: 'radiology' },
  { de: 'Rechtsmedizin', value: 'forensic' },
  { de: 'Strahlentherapie', value: 'radiotherapy' },
  { de: 'Transfusionsmedizin', value: 'transfusion' },
  { de: 'Urologie', value: 'urology' },
];

export const focus = [
  { de: 'FOCUS1', value: 'focus1' },
  { de: 'FOCUS2', value: 'focus2' },
];
