import { AddressDto } from './address.dto';

export class PropertyDto {
  address: AddressDto = new AddressDto();

  squareMetres?: number;

  ownership?: String;

  acquisition?: String[];

  waitingRoomSeatPlaces?: number;

  parkingPlaces?: number;

  publicTransports?: String[];
}
